import Search from './Search'
import TypeSelector from './TypeSelector'

export default function FilterGroup() {
  return (
    <>
      <div className="row flex-md-row mb-3">
        <div className="col-md-9 mb-md-0 mb-3">
          <Search/>
        </div>
        <div className="col-md-3 ps-md-1">
          <TypeSelector/>
        </div>
      </div>
    </>
  )
}
