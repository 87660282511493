import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import ErrorPage from './pages/404'
import About from './pages/about'
import Author from './pages/author'
import Main from './pages/main'
import { StoreProvider } from './store'
import Player from './components/Player'

function App() {
  return (
    <StoreProvider>
      <BrowserRouter>
        <Switch>    
          <Route exact path='/'>
            <Main/>
          </Route>    
          <Route path='/about'>
            <About/>
          </Route>
          <Route path='/author/:author' component={Author}>
          </Route>
          <Route path='/404'>
            <ErrorPage/>
          </Route>
          <Redirect to='/404'/>
        </Switch>
      </BrowserRouter>
      <Player/>
    </StoreProvider>
  )
}

export default App
