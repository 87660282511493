import {IContinuation, ITrack} from "../../../interfaces/track"
import './index.scss'
import { Play, Stop, Heart } from 'react-bootstrap-icons'
import { useStore } from '../../../store'
import {Link} from "react-router-dom";

export interface ITrackProps{
  track: ITrack,
  context: IContinuation
}

export default function Track({track, context}: ITrackProps) {
  const { dispatch, state } = useStore()
  const { playingTrackId } = state

  const playTrack = (track: ITrack) => () => {
    dispatch({ type: 'playTrack', activeTrack: track, context: context })
  }

  const stopTrack = (track: ITrack) => () => {
    dispatch({ type: 'stopTrack', activeTrack: track })
  }

  return(
    <div className="align-items-center bg-white rounded d-flex py-2 px-3">
      <div>
      {playingTrackId !== track.id 
      ? <Play className="me-2 play-button text-black-50" size={32} onClick={playTrack(track)}/> 
    : <Stop className="me-2 play-button text-black-50" size={32} onClick={stopTrack(track)}/> }
    </div>
      <div className="mb-0 nowrap flex-grow-1"><Link to={"/author/"+encodeURIComponent(track.authorId)}>{track.author}</Link> - {track.title}</div>
      <div className="d-flex align-items-center">
        <div className="px-1">{track.votes}</div>
        <Heart className="" size={16}/></div>
    </div>
  )
}
