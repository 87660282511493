import axios from 'axios'
import { useStore } from '../../../store'
import { Loading } from '../../Loading'
import TrackItem from './TrackItem'

export default function Tracks() {
  const { dispatch, state } = useStore()
  const { tracks, tracksLimit, searchQuery } = state

  const loadMoreTracks = async ()  => {
    let url = `/api/tracks`
    const params = { start: tracksLimit, limit: 10, q: searchQuery }
    const response = await axios.get(url, {params})
    const {data} = response
    if (response.status >= 200 && response.status < 300) {
      dispatch({ type: 'appendTracks', newTracks: data })
    }
  }

  return(
    <>
      <div className="bg-white row g-0 border rounded p-4">
        <h4>Все треки</h4>
        {
          tracks ? <> {
            tracks.map(track => (
              <TrackItem key={track.id} track={track} context='all'/>
            ))
          } 
          <button type="button" onClick={() => loadMoreTracks()} className="btn btn-secondary shadow-none col-md-3 mx-auto mt-4">Загрузить еще</button> 
          </> 
          : <Loading/>
        }        
      </div>
    </>
  )
}
