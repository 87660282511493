import {MainLayout} from '../components/MainLayout'
import './about.scss'

export default function About() {
    return (
        <MainLayout>
            <div className="bg-white border rounded min-vh-100 p-4 about">
                <h3>Музыкальные челленджи в телеграме</h3>
                <p>
                    Есть телеграм-канал <a href="https://t.me/PesnoPisets">@PesnoPisets</a>, в нем регулярно проводятся
                    музыкальные челленджи. Участники за ограниченное время пишут музыкальные произведения на какую-то заданную тему.
                    Задания придумывает победитель предыдщуего раунда либо сообщество, а раунд длится от 2 до 4 недель.
                </p>
                <p>
                    Подробнее можно почитать <a href="https://telegra.ph/FAQ-po-PesnoPiscu-02-10">в кодексе песнописца</a>
                </p>
                <h3>Ссылки на каналы</h3>
                <ul>
                    <li><a href="https://t.me/PesnoPisets">Основной чат. Здесь проходит обсуждение работ, заданий и
                        прочее</a></li>
                    <li><a href="https://t.me/AAAAAFJ0z1">Архив работ. Тут хранятся сами работы и видны результаты
                        голосований</a></li>
                </ul>
            </div>
        </MainLayout>
    )
}
