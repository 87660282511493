import { useState, useEffect } from 'react'
import { ICurrentTask } from '../../interfaces/currentTask'
import { Loading } from '../Loading'
import { ReadMore } from '../ReadMore'

export function CurrentTask() {
  const [currentTask, setCurrentTask] = useState<ICurrentTask>()

  useEffect(() => {
    async function load() {
      const response = await fetch(`/api/round/current`)
      const data = await response.json()
      if (response.ok) {
        setCurrentTask(data)
      }
    }

    load()
    
  }, [])
  
  return(
    <>
     <div className="bg-white row g-0 border rounded mb-3 p-4">
      {
        currentTask ? <>
          <div className="col-md-8 pe-4">
            <h5 className="">{currentTask.title}</h5>
            {currentTask.state === "voting" && <h5>RIGHT NOW: <a className="notification" href='https://t.me/AAAAAFJ0z1'>Идет голосование!</a></h5>}
            {currentTask.state !== "closed" && <ReadMore limit={230}>{currentTask.task}</ReadMore>}
          </div>
          {
          currentTask.isActive &&
            <div className="col-md-4 pt-4">
              <p className="text-right m-0">дедлайн до</p>
              <h3 className="text-right">{currentTask.deadline}</h3>
            </div>
          }
        </> : 
        <Loading/>
      } 
    </div>
    </>
  )
}
