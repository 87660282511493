import FilterGroup from "./FilterGroup"
import Rounds from "./Rounds"
import Tracks from "./Tracks"
import { useStore } from "../../store"

export default function Content() {
  const {state} = useStore()
  const {displayType} = state
  return(
    <>
      <FilterGroup/>
      { displayType === 'tracks' ? <Tracks /> : <Rounds/> }
    </>
  )
}
