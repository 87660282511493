import {useState} from "react"
import './index.scss'

interface IProps {
    children: string
    limit: number
}

const linkRegex = new RegExp(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/);

const renderBlock = (line: string) => {

    const chunks = line.split(linkRegex);

    return chunks.filter(ch=>ch.length > 0).map((chunk,nChunk) => 
        chunk.match(linkRegex) 
            ? <a className='external-link' key={nChunk} href={chunk}>{chunk.replace(/.+\/\/|www.|\/.+/g, '')+' ↗'}</a> 
            : <span key={nChunk}>{chunk}</span>);
}

export function ReadMore({children, limit}: IProps) {
    const text = children || ''
    const [isReadMore, setIsReadMore] = useState(true)
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore)
    }

    const hasMore = text.length > limit;

    const displayText = isReadMore && hasMore ? text.slice(0, limit - 3) + "..." : text;

    return (
        <div className="text">
            {renderBlock(displayText)}
            {
                hasMore &&
                <div onClick={toggleReadMore} className="read-or-hide mt-2">
                    {isReadMore ? "Показать больше..." : "Показать меньше"}
                </div>
            }
        </div>
    )
}