import { Reducer } from 'react'
import {IContinuation, ITrack} from './interfaces/track'
import { IRound } from './interfaces/round'

export type Action = 
  { type: 'playTrack'; activeTrack: ITrack, context: IContinuation } |
  { type: 'pauseTrack'; activeTrack: ITrack } |
  { type: 'stopTrack'; activeTrack: ITrack } |
  { type: 'resumeTrack'; activeTrack: ITrack } |
  { type: 'nextTrack'; activeTrack: ITrack } |
  { type: 'search'; query: string } |
  { type: 'setSearchQuery'; searchQuery: string } |
  { type: 'setRounds'; rounds: IRound[] } |
  { type: 'appendRounds'; newRounds: IRound[] } |
  { type: 'setTracks'; tracks: ITrack[] } |
  { type: 'appendTracks'; newTracks: ITrack[] } |
  { type: 'setDisplayType'; displayType: 'tracks' | 'rounds' } |
  { type: 'setPlayerVisible'; visible: boolean }

export interface State {
  activeTrack: ITrack | null
  nextTrackContext: IContinuation
  searchQuery: string | null
  rounds: IRound[] | null
  tracks: ITrack[] | null
  tracksLimit: number
  roundsLimit: number
  displayType: 'tracks' | 'rounds'
  playerVisible: boolean
  playingTrackId: string | null
}

export const initialState: State = {
  activeTrack: null,
  nextTrackContext: 'all',
  searchQuery: null,
  rounds: null,
  tracks: null,
  tracksLimit: 0,
  roundsLimit: 0,
  displayType: 'rounds',
  playerVisible: false,
  playingTrackId: null
}

export const reducer: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case 'playTrack': {
      return { 
        ...state, 
        playerVisible: true,
        nextTrackContext: action.context,
        activeTrack: action.activeTrack,
        playingTrackId: action.activeTrack.id
      }
    }
    
    case 'nextTrack': {
      return { 
        ...state, 
        playerVisible: true,
        activeTrack: action.activeTrack,
        playingTrackId: action.activeTrack.id
      }
    }

    case 'stopTrack': {
      return { 
        ...state, 
        playerVisible: false,
        activeTrack: null,
        playingTrackId: null
      }
    }

    case 'pauseTrack': {
      return { 
        ...state,
        playingTrackId: null
      }
    }

    case 'resumeTrack': {
      return { 
        ...state,
        playingTrackId: action.activeTrack.id
      }
    }


    case 'search': {
      return { ...state}
    }

    case 'setSearchQuery': {
      return { ...state, searchQuery: action.searchQuery}
    }

    case 'setRounds': {
      return { 
        ...state, 
        rounds: action.rounds,
        roundsLimit: action.rounds.length
      }
    }

    case 'appendRounds': {
      const updatedRounds = state.rounds ? state.rounds.concat(action.newRounds) : action.newRounds
      return { 
        ...state, 
        rounds: updatedRounds,
        roundsLimit: updatedRounds.length
      }
    }

    case 'setTracks': {
      return { 
        ...state, 
        tracks: action.tracks,
        tracksLimit: action.tracks.length
      }
    }

    case 'appendTracks': {
      const updatedTracks = state.tracks ? state.tracks.concat(action.newTracks) : action.newTracks
      return { 
        ...state, 
        tracks: updatedTracks,
        tracksLimit: updatedTracks.length
      }
    }

    case 'setDisplayType': {
      return { ...state, displayType: action.displayType}
    }

    default:
      return state
  }
}
