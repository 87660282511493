import Round from './Round'
import { useStore } from '../../../store'
import { Loading } from '../../Loading'
import './index.scss'
import axios from 'axios'

export default function Rounds() {
  const { dispatch, state } = useStore()
  const { rounds, roundsLimit, searchQuery } = state

  const loadMoreRounds= async ()  => {
    let url = `/api/rounds`
    const params = { start: roundsLimit, limit: 10, q: searchQuery }
    const response = await axios.get(url, {params})
    const {data} = response
    if (response.status >= 200 && response.status < 300) {
      dispatch({ type: 'appendRounds', newRounds: data })
    }
  }

  return(
    <>
      <>
        <div className="bg-white row g-0 border rounded flex-md-row p-4">
          <h4>Завершенные раунды</h4>
          {rounds ? <> {
            rounds.map((round, i) => {
              round.n = i%3
              return (
                <Round key={round.id} {...round}/>
              )
              }) }
            <button type="button" onClick={() => loadMoreRounds()} className="btn btn-secondary shadow-none mx-auto col-md-3 mt-4">Загрузить еще</button>
          </> :
            <Loading/>
          }
        </div>
      </>
    </>   
  )
}
