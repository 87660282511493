import { ReactNode } from "react"
import { Link } from "react-router-dom"

interface IProps {
  children: ReactNode
}

export function MainLayout({ children}: IProps) {
  return (
    <>
      <div className="col-lg-8 mx-auto pb-3">
        <nav className="navbar navbar-expand-md navbar-dark">
          <div className="container-fluid">
          <Link className="navbar-brand logo" to="/">PESNOCLOUD</Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <ul className="navbar-nav me-auto mb-2 mb-md-0">
                {/* <li className="nav-item">
                  <a className="nav-link" aria-current="page" href="#">Задания</a>
                </li> */}
              </ul>
              <div className="d-flex navbar-nav">
                <li className="nav-item">
                <Link className="nav-link" to='/about'>Что это?</Link>
                </li>
              </div>
            </div>
          </div>
        </nav>

        <main className="px-3 pb-5 px-md-0 min-vh-100">
          {children}
        </main>
      </div>
    </>
  )
}