import { MainLayout } from '../components/MainLayout'
import { CurrentTask } from '../components/CurrentTask'
import Content from '../components/Content'

export default function Main () {
  return (
    <MainLayout>
      <CurrentTask/>
      <Content/>
    </MainLayout>
  )
}


