import {MainLayout} from '../components/MainLayout'
import {RouteComponentProps} from "react-router";
import TrackItem from "../components/Content/Tracks/TrackItem";
import {Loading} from "../components/Loading";
import {useEffect, useState} from "react";
import axios from "axios";
import {IAuthor} from "../interfaces/author";
import {Link} from "react-router-dom";


export default function Author(props: RouteComponentProps<{ author: string }>) {

    const [author, setAuthor] = useState<IAuthor>();

    useEffect(() => {
        window.scrollTo(0, 0);
        loadMoreTracks();

    },[]);

    const loadMoreTracks = async () => {
        
        const response = await axios.get('/api/author/'+props.match.params.author);

        if (response.status >= 200 && response.status < 300)
            setAuthor(response.data);
    };

    return (
        <MainLayout>
            <div className="bg-white border rounded min-vh-100 p-4">
                <Link className='btn shadow-none btn-secondary my-2' to="/" >⬅ Назад</Link>
                {
                    author ? <>
                            <h4>Все треки {author.name}</h4>
                            {
                            author.tracks.map(track => (
                                <TrackItem key={track.id} context='author' track={track}/>
                            ))
                        }
                        </>
                        : <Loading/>
                }
            </div>
        </MainLayout>
    )
}
