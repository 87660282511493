import { useEffect } from 'react'
import { useStore } from '../../../store'
import { DebounceInput } from 'react-debounce-input'
import axios from 'axios'

export default function Search() {
  const { dispatch, state } = useStore()
  const { searchQuery, displayType } = state

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: 'setSearchQuery', searchQuery: event.target.value })
  }

  useEffect(() => {
    async function load() {
      let url = `/api/${displayType}`
      const params = { q: searchQuery, start: 0, limit: 10 }
      const response = await axios.get(url, {params})
      const data = response.data
      if (response.status >= 200 && response.status < 300) {
        switch (displayType) {
          case 'rounds':
            dispatch({ type: 'setRounds', rounds: data })
            break;
          case 'tracks':
            dispatch({ type: 'setTracks', tracks: data })
            break;
          default:
            break;
        }
      }
    }

    load()
  }, [searchQuery, displayType, dispatch])

  return (
    <>
      <div className="input-group">
        <DebounceInput
          className="form-control" 
          minLength={2}
          debounceTimeout={300}
          placeholder="Поиск" 
          aria-label="Поиск" 
          onChange={onChangeHandler} />
    </div>
    </>
  )
}
