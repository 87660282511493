import { IRound } from "../../../interfaces/round"
import { ReadMore } from "../../ReadMore"
import Track from "../Tracks/Track"

export default function Round(round: IRound) {
  return(
    <>
      <div className="row g-0 rounded flex-md-row mt-3 p-3 round-card ">
        <div className={`col-md-5 rounded p-3 mb-md-0 mb-3 round-description-${round.n}`}>
          <div>
            <h5>{round.title}</h5>
            <div className="small pb-2">{round.startDate} - {round.endDate}</div>
          </div>
          <ReadMore limit={250}>{round.task}</ReadMore>
        </div>
        <div className="col-md-7 ps-md-3">
          {round.tracks.map(track => (
            <div key={track.id} className="mb-2">
              <Track track={track} context='round'/>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
