import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player'
import 'react-h5-audio-player/src/styles.scss'
import './index.scss'
import { useStore } from '../../store'
import { play, forward, rewind, pause, volume, volumeMute }from './icons'
import { ITrack } from '../../interfaces/track'
import axios from "axios";

export default function Player () {
  const { state, dispatch } = useStore()
  const { activeTrack, nextTrackContext, playerVisible } = state

  const nextTrack = async (prev: ITrack)  => {
    let url = `/api/track/next/${encodeURIComponent(prev.id)}`
    const params = { loop: true, context: nextTrackContext }
    const response = await axios.get(url, {params})
    const {data} = response
    if (response.status >= 200 && response.status < 300) {
      dispatch({ type: 'nextTrack', activeTrack: data })
    }
  }

  const pauseTrack = (track: ITrack) => dispatch({type: 'pauseTrack', activeTrack: track})
  const resumeTrack = (track: ITrack) => dispatch({type: 'resumeTrack', activeTrack: track})

  if (playerVisible)
    return (
      <div className="player">
        <AudioPlayer
          autoPlay
          showSkipControls={true}
          showJumpControls={false}
          src={activeTrack?.audioUrl}
          onPlay={e => activeTrack!==null && resumeTrack(activeTrack)}
          onPause={e => activeTrack!==null && pauseTrack(activeTrack)}
          onEnded={e => activeTrack!==null && nextTrack(activeTrack)}
          onClickNext={e => activeTrack!==null && nextTrack(activeTrack)}
          
          customProgressBarSection={
            [
              RHAP_UI.MAIN_CONTROLS,
              RHAP_UI.CURRENT_TIME,
              RHAP_UI.PROGRESS_BAR,
              RHAP_UI.DURATION,
              RHAP_UI.VOLUME,
            ]
          }
          customControlsSection={[]}
          customIcons={{
            previous:rewind,
            next:forward,
            play,
            pause,
            volume,
            volumeMute
          }}
        />
      </div>
    )
  else return null
}