import { ReadMore } from "../../ReadMore"
import './index.scss'
import Track, {ITrackProps} from "./Track"


export default function TrackItem({track, context}: ITrackProps) {

  return(
    <>
      <div className="rounded d-flex flex-md-row flex-column mt-3 p-md-2 p-3 track-card">
        <div className="track-cover mb-md-0 mb-3 ">
          <img src={track.imageUrl || 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='} alt=""/>
        </div>
        <div className="flex-fill ps-md-2 d-flex flex-column overflow-hidden">
          <Track track={track} context={context}/>
          <div className="bg-white rounded py-2 px-3 mt-2 flex-fill d-flex">
            {track.description ? 
            <ReadMore limit={200}>{track.description}</ReadMore> 
            : <div className="align-self-center d-flex justify-content-center w-100 text-secondary">Нет описания ¯\_(ツ)_/¯</div>}
            </div>
        </div>
      </div>
    </>
  )
}
