import { useStore } from '../../../store'

export default function TypeSelector() {
  const { dispatch, state } = useStore()
  const { displayType } = state

  const changeDisplayType = (displayType: 'tracks' | 'rounds')  => {
    dispatch({ type: 'setDisplayType', displayType })
  }

  return (
    <>
      <div className="input-group">
        <button 
          onClick={() => changeDisplayType('rounds')} 
          type="button" 
          className={`btn col-6 shadow-none ${displayType === 'rounds' ? 'btn-primary' : 'btn-secondary'}`}>
            Раунды
        </button>
        <button 
          onClick={() => changeDisplayType('tracks')} 
          type="button" 
          className={`btn col-6 shadow-none ${displayType === 'tracks' ? 'btn-primary' : 'btn-secondary'}`}>
            Треки
        </button>        
      </div>
    </>
  )
}
