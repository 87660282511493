import { Link } from 'react-router-dom'
import {MainLayout} from '../components/MainLayout'

export default function ErrorPage() {
  return (
    <MainLayout>
      <div className = "bg-white border rounded">
      <div className="col-lg-3 mx-auto my-5">
        <h1>Error 404</h1>
        <Link to="/">Перейти на главную</Link>
      </div>
      </div>
    </MainLayout>
  )
}